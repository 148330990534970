<template>
    <!-- <div class="n_button_div ion-text-center" v-show="paginationData.totalPage > 0 && paginationData.lastPage > globalSet.apiParams.page">
        <button class="n_button" @click="moreList()">More loading</button>
    </div> -->
    <infinite-loading @infinite="loadMore" spinner="circles" v-if="paginationData.total > 0 && paginationData.total > paginationData.page * paginationData.size" >
        <span slot="no-more"></span>
        <span slot="no-results"></span>
    </infinite-loading>

</template>
<script>
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'LazyloadLoadingLayout',
        inject: ['setPage'],
        props: {
            paginationData: {
                type: Object,
                default: {
                    total: 0,
                    page: 0,
                    size: 0,
                }
            },
            setData: {
                type: Function,
                default: () => {}
            },
            boardType: {}
        },
        mixins: [],
        data() {
            return {
            }
        },
        computed: {

        },
        mounted() {

        },
        methods: {

            /*loadMore($event) {
                let page= this.paginationData.page;
                page = page + 1;
                this.setPage('page', page);
                // EventBus.$emit('getBoardList')
                setTimeout(() => {
                    $event.target.complete();

                    if(this.paginationData.totalPage > page) {
                    }else{
                        // $event.target.disabled = true;
                    }
                }, 500)
            },*/
            loadMore($state) {
                let page= this.paginationData.page;
                page = page + 1;
                this.setPage('page', page);
                // EventBus.$emit('getBoardList')
                setTimeout(() => {
                    $state.loaded();
                    /*f(this.paginationData.totalPage > page) {
                        $event.target.complete();
                    }else{
                        $event.target.disabled = true;
                    }*/
                }, 500)
            },
        }
    }
</script>